<template>
  <div class="product">
    <div class="product__labels" :class="{ 'has-discount': discount }">
      <span v-if="discount" class="product__info-sale" :class="[discountTypeClassName]">
        {{ discountLabel }}
      </span>
      <div v-if="isNewProduct" class="new-label">NEW</div>
    </div>

    <div class="product__thumb">
      <img :src="productImage" class="product__img" :class="{ blurred: !isStockAvailable }" />

      <div>
        <a
          v-if="!isStockAvailable"
          href
          class="btn btn-dark product__button-add-to-bag product__button-add-to-bag--out-of-stock"
          >OUT OF STOCK</a
        >
      </div>

      <!-- dummy combination product -->
      <div v-if="combinationAttributes" class="combination-wrapper">
        <p class="product__combination">{{ combinationAttributes }}</p>
      </div>
      <!-- dummy combination product -->
    </div>

    <div class="product__info">
      <p class="product__brand">{{ $get(product, 'brand.name', '') }}</p>
      <p class="product__name">
        <span class="product__name">{{ product.name }}</span>
      </p>

      <h2
        v-if="finalPrice"
        class="product__price"
        :class="{ 'product__price_c-danger': priceBeforeDiscount && discount }"
      >
        <span ref="discountPrice">{{ finalPrice }}</span>
        <div v-if="priceBeforeDiscount && discount" ref="normalPrice" class="product-price__stroke">
          {{ priceBeforeDiscount }}
        </div>
        <!-- <div class="product__info-discount" ref="discountTotal" v-if="discount">{{ discount }}</div> -->
      </h2>

      <div v-show="ratingStars" class="block-snippet-product">
        <div class="rating">
          <div class="rs big-star" :class="['new-rs-' + ratingStars]">
            <span></span>
            <!-- <span></span>
            <span></span>
            <span></span>
            <span></span> -->
            <p style="margin-left: 2px">{{ ratingValue }}</p>
          </div>
          <!-- <span v-if="Number(totalReviews)" class="product__reviews">({{ totalReviews | nFormatter }})</span> -->
        </div>
      </div>
    </div>
    <slot name="cart-action" />
  </div>
</template>

<script>
export default {
  name: 'DisplayProduct',
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ratingStars() {
      if (this.$get(this.product, 'review_stats.average_rating', 0) <= 0) {
        return null;
      }

      return (
        this.$options.filters.newRating(Number.parseFloat(this.$get(this.product, 'review_stats.average_rating', 0))) *
        10
      );
    },
    ratingValue() {
      const averageRating = this.$get(this.product, 'review_stats.average_rating', 0);
      return averageRating ? averageRating.toFixed(1) : '0.0';
    },
    isStockAvailable() {
      //return (this.product.is_in_stock && !this.product.is_out_of_stock_sociolla) || (!this.product.default_combination.is_out_of_stock_sociolla && this.vendingStock);
      return this.vendingStock && this.maxQty > 0;
    },
    qty_on_cart() {
      let qty_product_on_cart = 0;
      const carts = this.$store.state.vmCola.footer_cart;
      if (carts && carts.length) {
        carts.forEach((res) => {
          if (res.raw.detail.vending_slot == this.product.vending_slot) {
            qty_product_on_cart += Number(res.qty);
          }
        });
      }
      return qty_product_on_cart;
    },
    maxQty() {
      return Number(this.vendingStock) - this.qty_on_cart;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    combinationAttributes() {
      if (this.product.default_combination && this.product.default_combination.attributes) {
        const combination_name = [];
        const attributes = this.product.default_combination.attributes;
        for (const attribute in attributes) {
          if (this.product.default_combination.attributes.hasOwnProperty(attribute)) {
            if (attribute !== 'non_specify') {
              combination_name.push(`${attribute}: ${attributes[attribute].name}`.trim());
            }
          }
        }
        return combination_name.join(', ');
      }
    },
    totalReviews() {
      return this.$get(this.product, 'review_stats.total_reviews', 0);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    discount() {
      if (this.product.default_combination.deduction_type) {
        return this.product.default_combination.deduction_type == 'amount'
          ? `${this.product.default_combination.deduction_amount}`
          : `${this.product.default_combination.deduction_percentage}`;
      }
    },
    discountLabel() {
      if (this.discountType === 'percentage') {
        return `${this.product.default_combination.deduction_percentage}%`;
      }
      return 'SALE';
    },
    discountType() {
      return this.product.default_combination.deduction_type == 'amount' ? 'discount' : 'percentage';
    },
    discountTypeClassName() {
      if (this.discount) {
        return `product__info-sale--${this.discountType}`;
      }
      return '';
    },
    finalPrice() {
      const final_price = this.product.default_combination.price_after_discount
        ? this.product.default_combination.price_after_discount
        : this.product.default_combination.price;
      return this.$options.filters.currencyRp(final_price);
    },
    priceBeforeDiscount() {
      return this.$options.filters.currencyRp(this.product.default_combination.price);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    productImage() {
      const prdImg = this.product.images && this.product.images.length && this.product.images[0];
      const defaultCombImg =
        this.product.default_combination &&
        this.product.default_combination.images &&
        this.product.default_combination.images.length;
      if (prdImg || defaultCombImg) {
        let imageCover;
        if (prdImg && this.product.images.findIndex((res) => res.is_cover) > -1) {
          imageCover = this.product.images.find((res) => res.is_cover).url;
        }
        if (
          defaultCombImg &&
          this.product.default_combination.images[0] &&
          this.product.default_combination.images.findIndex((res) => res && 'is_cover' in res && res.is_cover) > -1
        ) {
          imageCover = this.product.default_combination.images.find((res) => res.is_cover).url;
        }
        return imageCover;
      }
    },
    vendingStock() {
      return Number(this.product.vending_stock);
    },
    isNewProduct() {
      let isNew = false;
      if (this.product.is_new == 'new' || this.product.combinations[0].status_item === 'new') {
        isNew = true;
      }
      return isNew;
    },
  },
  methods: {
    openModalQuickView() {
      if (this.product.classification != 'bundle_virtual') {
        this.$store.commit('SET_QUICK_VIEW_PRODUCT_ID', this.product.id);
        this.$store.commit('SET_QUICK_VIEW_DATA', this.product);
        this.$store.commit('SHOW_MODAL', 'quickView');
      }
    },
  },
};
</script>
<style scoped>
.blurred {
  filter: blur(5px);
  opacity: 0.5;
}
</style>
